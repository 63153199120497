import Shopping from '../containers/Shopping';
import '../styles/App.css';

function App() {
  return (
    <>
      <Shopping products={[]} />
    </>
  );
}

export default App;
