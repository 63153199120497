import React, { useState } from 'react';
// import Lottie from 'lottie-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Product from '../components/product';
// import Smoothies from './Smoothies';
// import Juices from './Juices';
// import Frappes from './Frappes';
// import Licuados from './Licuados';
// import Cafe from './Cafes';
// import Daiquiris from './Daiquiris';
// import Salads from './Salads';
// import Avocados from './Avocados';
// import Paninis from './Paninis';
import '../styles/shopping.css';
import '../styles/style.scss';
// import Waffles from './Waffles';
// import OmeletteList from '../constants/omelettes';
// import ChilaquilesList from '../constants/chilaquiles';
import { removeProductAction, addProductAction } from '../actions';
// import Classic from './Classic';
// import Logo from '../assets/logomenu.png';
// import Logo from '../assets/Lottiefiles/LogoLottie.json';
// import Stars from '../assets/Lottiefiles/Stars.json';
// import Bublle from '../assets/Lottiefiles/BubbleDiscret.json';
import Promos from './Promos';
// import Season from './Season';
// import PromoImage from '../assets/Promo/Promo16.jpg';

const Shopping = props => {
  const { products } = props;

  const [total, setTotal] = useState(0);

  const handleAddProduct = product => {
    const { addProduct } = props;
    addProduct(product);
    setTotal(total + product.precio);
  };

  const handleRemoveBook = product => {
    const { removeProduct } = props;
    removeProduct(product);
    setTotal(total - product.price);
  };

  return (
    <div className="pb-5">

      <div>
        <div className="d-flex justify-content-center align-items-center bg-white ">
          {/* <img src={Logo} alt="logo" className="logo_menu" /> */}
          {/* <Lottie
            animationData={Stars}
            style={{ position: 'absolute' }}
            className="logo_menu w-100"
            speed="0.1"
          /> */}
          {/* <Lottie animationData={Logo} loop={false} className="logo_menu" /> */}
        </div>
        <div className="row bg-white">
          <div className="col-sm-12 col-lg-6 pt-4">
            {/* <Lottie
              animationData={Bublle}
              className="h-100 position-absolute"
            />
            <Season updateShopping={handleAddProduct} /> */}
            <Promos updateShopping={handleAddProduct} />
          </div>
          <div className="col-sm-12 col-lg-6 d-flex align-items-center justify-content-center">
            <div className="row d-flex align-items-center justify-content-center">
              <div className="d-flex align-items-center justify-content-center">
                {/* <img
                  src={PromoImage}
                  alt="Promo"
                  className="w-100"
                  style={{ maxWidth: 400 }}
                /> */}
              </div>
              {/* <Lottie
                animationData={Bublle}
                className="h-100 position-absolute"
              /> */}
            </div>
          </div>
        </div>
        {/* <h1 className="w-100 text-center instructions c-white">
          AGREGA TUS PRODUCTOS UTILIZANDO EL BOTON (+). AL FINAL PODRÁS
          {' '}
          <a href="#bootom" className="link_bottom">
            ENVIAR TU LISTA
          </a>
          {' '}
          POR WHATSAPP
        </h1> */}
      </div>
      {/* <div className="row">
        <div className="col-sm-12 col-lg-6">
          <Smoothies updateShopping={handleAddProduct} />
        </div>
        <div className="col-sm-12 col-lg-6">
          <Juices updateShopping={handleAddProduct} />
        </div>
      </div> */}
      {/*
      <div className="row">
        <div className="col-sm-12 col-lg-5">
          <Frappes updateShopping={handleAddProduct} />
        </div>
        <div className="col-sm-12 col-lg-6">
          <Daiquiris updateShopping={handleAddProduct} />
          <Licuados updateShopping={handleAddProduct} />
          <section id="bebidasCalientes">
            <Cafe updateShopping={handleAddProduct} />
          </section>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12 col-lg-6">
          <Salads updateShopping={handleAddProduct} />
          <Avocados updateShopping={handleAddProduct} />
          <Classic
            title="Chilaquiles"
            List={ChilaquilesList}
            updateShopping={handleAddProduct}
          />
        </div>
        <div className="col-sm-12 col-lg-6">
          <Paninis updateShopping={handleAddProduct} />
          <Waffles handleAddProduct={handleAddProduct} />
          <Classic
            title="Omelettes"
            List={OmeletteList}
            updateShopping={handleAddProduct}
          />
        </div>
      </div> */}

      <div className="seleccionados"> . </div>
      <div>
        {products.map(product => (
          <Product
            key={product.name}
            product={product}
            deleteproduct={handleRemoveBook}
          />
        ))}
      </div>

      <a href="#bootom">
        <div className="subtotal">
          <p className="text_subtotal">
            $
            {total}
          </p>
        </div>
      </a>

    </div>
  );
};
Shopping.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      precio: PropTypes.number,
      quatity: PropTypes.number,
    }),
  ).isRequired,
  addProduct: PropTypes.func.isRequired,
  removeProduct: PropTypes.func.isRequired,
  // url: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  products: state.products,
  // url: state.url,
});

const mapDispatchToProps = dispatch => ({
  removeProduct: book => {
    dispatch(removeProductAction(book));
  },
  addProduct: category => {
    dispatch(addProductAction(category));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Shopping);
