import PropTypes from 'prop-types';
import BasicWhite from '../components/BasicWhite';
import PromoList from '../constants/promos';
import '../styles/Promos.css';

const Promos = props => {
  const { updateShopping } = props;
  return (
    <div className="row">
      <div className="col-12">
        {/* <p className="title-jungle d-flex justify-content-center">
          <span className="p-shadow">Promo</span>
          <span className="y-shadow">16oz</span>
        </p> */}
        <p className="subtitle-jungle pink-shadow-md d-flex justify-content-center" style={{ color: 'white' }}>.</p>
        <div className="p-3">
          {PromoList.map(promo => (
            <BasicWhite
              key={promo.id}
              product={promo}
              updateShopping={updateShopping}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

Promos.propTypes = {
  updateShopping: PropTypes.func.isRequired,
};

export default Promos;
