import PropTypes from 'prop-types';
import '../styles/basicwhite.css';

const BasicWhite = props => {
  const { product, updateShopping } = props;
  const colors = ['#772095', '#CD1401', '#0BC4D7', '#80D70B', '#D5144E', '#D1AF07', '#CD0161', '#C82A54', '#8C4966', '#99C140', '#024A86', '#E36B2C', '#EF280F', '#D62976', '#288BA8', '#711997', '#4F5BD5', '#FA7E1E', '#493267'];
  const colorFill = colors[Math.floor(Math.random() * (17 - 0)) + 0].toString();
  return (
    <div className="col-12">
      <div className="row pb-4 m-0">
        <div className="col-2 m-0 p-0">
          <svg
            className="tag"
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 100 100"
            enableBackground="new 0 0 100 100;"
          >
            <g>
              <polygon
                fill={colorFill}
                points="97,50.88 88.9,58.6 93.44,68.83 82.99,72.84 83.27,84.03 72.08,83.76 68.06,94.2 57.84,89.66
              50.12,97.76 42.4,89.66 32.17,94.2 28.16,83.75 16.97,84.03 17.25,72.84 6.8,68.83 11.34,58.6 3.24,50.88 11.34,43.16 6.8,32.93
              17.25,28.92 16.97,17.73 28.16,18 32.17,7.56 42.4,12.1 50.12,4 57.84,12.1 68.06,7.56 72.08,18.01 83.27,17.73 82.99,28.92
              93.44,32.94 88.9,43.16"
              />
              <text x="50%" y="50%" alignmentBaseline="middle" textAnchor="middle" fill="white" stroke="none" className="price_text">
                $
                {product.precio}
              </text>
            </g>
          </svg>
        </div>
        <div className="col-8 m-0 p-0">
          <div>
            <p className="subtitle-jungle" style={{ color: colorFill }}>{product.name}</p>
            <p className="subtitle-jungle-black" style={{ color: 'black' }}>
              {' '}
              {product.description}
            </p>
          </div>
        </div>
        <div className="col-2 d-flex justify-content-center align-items-center m-0 p-0">
          <button className="button-price" type="submit" onClick={() => { updateShopping(product); }}>+</button>
        </div>
      </div>
      <div className="m-auto d-flex justify-content-center align-items-center salad" />
    </div>
  );
};

BasicWhite.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.number,
    description: PropTypes.string,
    name: PropTypes.string,
    precio: PropTypes.number,
    image: PropTypes.string,
  }).isRequired,
  updateShopping: PropTypes.func.isRequired,
};

export default BasicWhite;
